<template>
    <div class="board_main_box">
        <span class="standbyColor f16">预警公告：</span>
         <vue-seamless-scroll :data="listData" class="seamless-warp" :key="scrollKey" :class-option="defaultOption">
            <ul>
                <li class="f14 warning_info_box" v-for="(item,i) in listData" :key="scrollKey+i">
                    <span class="sinleline">{{ item.alarmContent }}</span>
                    <span>{{ item.alarmTime }}</span>
                </li>
            </ul>
        
        </vue-seamless-scroll>
        <span class="iconfont icon-laba f20 standbyColor"></span>
    </div>
</template>

<script>

   
    export default {

        props:{
           listData:{
               type: Array,
               default: () => []
                    
           },
           devId: {
             type: String
           }
        },
        data(){
            return {
                defaultOption: {
                
                    singleHeight: 19,
                    // waitTime: 1000,
                    // openWatch: true,
                    // hoverStop: true,
                    direction: 1,
                    limitMoveNum: 2
                
                },
                scrollKey: new Date().getTime(),
           
    
            }
        },
        created(){

        },
        methods:{
            updateData(){
               this.scrollKey = new Date().getTime()

            }
        }
    }
</script>

<style lang="scss" scoped>
.board_main_box{
        display: flex;
        align-items: center;
        background: #263844;
        padding: 8px 10px;
        border-radius: 5px;
        .warning_info_box{
            display: flex;
            align-items: center;
            height: 19px;
            line-height: 19px;
            & > span:first-child{
                display: inline-block;
                max-width: 500px;
            }
            & > span:last-child{
                flex-shrink: 0;
                color: #888;
                margin: 0 10px 0 20px;
            }
        }
        .seamless-warp {
            // width: 400px;
          height: 19px;
          overflow: hidden;
        //   position: relative;
        //   overflow-y: auto;
        }
        .mr10{
            margin-right: 10px;
        }
}
.warp {
    height: 19px;
    width: 360px;
    margin: 0 auto;
    overflow: hidden;
    ul {
      list-style: none;
      padding: 0;
      margin: 0 auto;
      li,
      a {
        display: block;
        height: 19px;
        line-height: 19px;
        display: flex;
        justify-content: space-between;
        font-size: 12px;
      }
    }
  }
</style>