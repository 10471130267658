<template>
 <div class="pictrue_main_box wh100 pr">
        <HnContent :hasData='hasData'>
           <template slot="left">
                <HnMenu :menuDevType='menuDevType' @change='devChange'></HnMenu>
           </template>
           <template slot="right">
               <div class="historyInsect_box wh100">
                    <div 
                    class="content_box video-box-bg-image wh100">
                        <div class="tools_box box-bg-radius-gradient">
                            <div class="tools_title_box">
                                <div>
                                    <span class="iconfont icon-shachongdeng baseColor"></span>
                                    <span>{{ devName }}</span> 
                                </div>
                                <!-- <template v-if="warnList && warnList.length">
                                   <warnBoard :listData ='warnList' :devId='dataFromInfo.devId'></warnBoard>
                                </template> -->
                            </div>
                            <!-- <HnDateSearch @update='dateUpdate'/> -->
                        </div>
                        <div class="top_content_box">
                           <div class="box-bg-radius-gradient pset_left_box pr">
                               <div class="title_box">杀虫统计</div>
                              
                               <animeImg class="mt-40"></animeImg>
                               <p>
                                   <span>总计杀虫：</span>
                                   <span class="baseColor">{{ insectNub }}头</span>
                               </p>    
                               
                               
                           </div>
                           <div 
                            element-loading-text="拼命加载中"
                            element-loading-spinner="el-icon-loading"
                            element-loading-background="rgba(40, 53, 63, 0.1)"
                            v-loading="dayLoading"
                            class="box-bg-radius-gradient pset_right_box pr">
                               <div class="title_box flex_box">
                                   <span>杀虫分析</span>
                                    <el-date-picker
                                    class="date-picker"
                                    size= 'small'
                                    v-model="dayFromInfo.sTime"
                                    @change='dayChange'
                                    value-format="yyyy-MM-dd"
                                    type="date"
                                    placeholder="选择日期">
                                    </el-date-picker>
                               </div>
                               <template v-if="dayChartsData">
                                <div class="wh100" ref="dayLineDome"></div>
                               </template>
                               <template v-else> 
                                <hn-emptyData text= '暂无数据' size= '180px'></hn-emptyData>
                               </template>
                           </div>
                        </div>
                        <div 
                        element-loading-text="拼命加载中"
                        element-loading-spinner="el-icon-loading"
                        element-loading-background="rgba(40, 53, 63, 0.1)"
                        v-loading="listLoading"
                        class="echarts_content_box box-bg-radius-gradient pr">
                            <div class="title_box flex_box"> 
                                <span>杀虫分析（月度）</span> <HnDateSearch :timeList='monthInfo' :defaultData= '4' @update='dateUpdate'/>
                            </div>
                            <template v-if="chartsData">
                                <div class="wh100" ref="lineDome"></div>
                            </template>
                            <template v-else> 
                               <hn-emptyData text= '暂无数据' size= '180px'></hn-emptyData>
                            </template>
                        </div>
                        
                     
                        
                    </div>
                </div>
           </template>
        </HnContent>
    </div>
   
</template>

<script>
    import { optionBarLine,optionMoreAxisBarLine } from '@/libs/echartsOption'
    import animeImg from './compoents/animeImg.vue'
    import menuFun from '@/mixins/menuFun'
    import warnBoard from '@/components/localComponent/board.vue'
    export default {
        mixins: [menuFun],
        components: {animeImg,warnBoard},
        data(){
          const nowDate = this.$day().format('YYYY-MM-DD')  
          
          return {
            updateKey: true,
             tableData:[],
             dataFromInfo:{
                  devId: '',
                  timeType: 4, 
                  sTime:'',
                  eTime: ''
             },
             dayFromInfo:{
                  devId: '',
                //   timeType: 3, 
                  sTime: nowDate,
             },
             devInfos: null,
             warnList: [],
             insectNub: 0,
             chartsData: null,
             dayChartsData: null,
             listLoading: false,
             dayLoading: false,
             menuDevType: ['杀虫灯设备'],
             monthInfo: [
          
                {
                    name: '近一月',
                    code: 4
                },
                {
                    name: '三月',
                    code: 5
                },
                {
                    name: '半年',
                    code: 6
                },
             ]  
          }
        },
        computed:{
            devName(){
               return this.devInfos ?.devName || '设备信息'
            }
        },
        methods:{
            //  设备杀虫总数
          async IotInsectLampNumber(id){
              const res = await this.$api.IotInsectLampNumber({id}) 
              this.insectNub = res ?.number || 0
           },
            // 设备切换
          devChange(infos) {
     
              this.devInfos = this.getDevInfo(infos)
              let data = infos[0]
              
              if(data){
                let { devId } = data
             
                this.dataFromInfo.devId = devId
                this.dayFromInfo.devId = devId
                
                this.reqIotInsectLampData()  // 月查询
                this.IotInsectLampData1Analyse() // 日查询
                // this.LogInsectLampAlarmContentList(devId) // 告警信息
                this.IotInsectLampNumber(devId) // 杀虫总数
              }
              
          },
          // 获取告警内容
          async LogInsectLampAlarmContentList(id){
     
              const res = await this.$api.LogInsectAlarmContentList({id})
            
              this.warnList = res
       
             
           
          }, 
        // 季度查询
          async reqIotInsectLampData(){

              this.listLoading= true
              const res = await this.$api.IotInsectLampDataAnalyse(this.dataFromInfo)
             
              setTimeout(()=> {
                    this.listLoading= false
             },500)
              if(res){
                 let { xAxis, charts} = res
                  
                 if(xAxis && xAxis.length){
                    let xData = []
                     xAxis.map(item =>{
                        if(item.includes(' '))
                          xData.push(item.split(' ')[0])
                    })
                    this.chartsData = { xAxis: xData, charts }  
                    this.$nextTick(()=> {
                        this.initEcharts('lineDome',optionMoreAxisBarLine(this.$echarts,this.chartsData,true))
                    })
                 } else {
                    this.chartsData = null 
                 } 
              }else {
                  this.chartsData = null
              }
              
          },
          extension(chart) {
         
                // 注意这里，是以X轴显示内容过长为例，如果是y轴的话，需要把params.componentType == 'xAxis'改为yAxis
                // 判断是否创建过div框,如果创建过就不再创建了
                // 该div用来盛放文本显示内容的，方便对其悬浮位置进行处理
                var elementDiv = document.getElementById('extension')
                if (!elementDiv) {
                    var div = document.createElement('div')
                    div.setAttribute('id', 'extension')
                    div.style.display = 'block'
                    document.querySelector('html').appendChild(div)
                }
                chart.on('mouseover', function (params) {
            
                    if (params.componentType == 'xAxis') {
                    var elementDiv = document.querySelector('#extension')
                    //设置悬浮文本的位置以及样式
                    var elementStyle =
                        'position: absolute;z-index: 99999;color: #fff;font-size: 12px;padding: 5px;display: inline;border-radius: 4px;background-color: #303133;box-shadow: rgba(0, 0, 0, 0.3) 2px 2px 8px'
                    elementDiv.style.cssText = elementStyle
                    elementDiv.innerHTML = params.value
                    document.querySelector('html').onmousemove = function (event) {
                        var elementDiv = document.querySelector('#extension')
                        var xx = event.pageX + 10
                        var yy = event.pageY - 30
                        elementDiv.style.top = yy + 'px'
                        elementDiv.style.left = xx + 'px'
                    }
                    }
                })
                chart.on('mouseout', function (params) {
                    //注意这里，我是以X轴显示内容过长为例，如果是y轴的话，需要改为yAxis
                    if (params.componentType == 'xAxis') {
                    var elementDiv = document.querySelector('#extension')
                    elementDiv.style.cssText = 'display:none'
                    }
                })
          },
          // 日查询
          async IotInsectLampData1Analyse(){
               this.dayLoading = true
               const res  =  await this.$api.IotInsectLampData1Analyse(this.dayFromInfo)
              
                setTimeout(()=> {
                    this.dayLoading= false
                },500)
                if(res){
                    let { xAxis, charts} = res
        
                    if(xAxis && xAxis.length){
                        this.dayChartsData = { xAxis, charts }  
                        this.$nextTick(()=> {
                            this.initEcharts('dayLineDome',optionBarLine(this.$echarts,this.dayChartsData))
                        })
                    } else {
                        this.dayChartsData = null 
                    } 
                }else {
                    this.dayChartsData = null
                }
          },
          dateUpdate(data){
           
             Object.assign(this.dataFromInfo,data)
             this.reqIotInsectLampData()
            //  this.reqIotInsectLampDataTable()
          },
          dayChange(val){
      
              this.dayFromInfo.sTime = val
              this.IotInsectLampData1Analyse()
          },
          // 初始化echarts
          initEcharts(dome,option) {
                
                let mychart = this.$echarts.init(this.$refs[dome]) 
                mychart.setOption( option ) 
                if(dome == 'dayLineDome') this.extension(mychart)
                window.addEventListener("resize", function() {
                    mychart.resize();
                }); 
          },
          tableRowClassName() {
            
                return 'header-row';
               
          },
          tableRow({rowIndex}){
                if(rowIndex%2 !== 0){
                 return 'table-row'
                }else{
                    return 'table-odd-row'
                }
          },
        }
    }
</script>

<style lang="scss" scoped>
 .historyInsect_box{
     box-sizing: border-box;
    //  padding: 20px 20px 0 20px;
     .content_box{
        box-sizing: border-box;
        padding: 40px 40px 20px 40px;
        .title_box{
             position: absolute;
             top: 20px;
             left: 0;
             padding: 0 20px;
             font-size: 16px;
             box-sizing: border-box;
             z-index: 20;
             &::after{
                 content: '';
                 display: block;
                 width: 3px;
                 height: 25px;
                 position: absolute;
                 left: 0;
                 top: 50%;
                 margin-top: -12.5px;
                 background: #00b38a;
             }
        }
        .flex_box{
            display: flex;
            align-items: center;
        }
        .tools_box{
            height: 60px;
            display: flex;
            align-items: center;
            .tools_title_box{
                width: 100%;
                padding: 0 20px;
                font-size: 16px;
                box-sizing: border-box;
                position: relative;
                display: flex;
                justify-content: space-between;
                
                &::after{
                    content: '';
                    display: block;
                    width: 3px;
                    height: 25px;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    margin-top: -12.5px;
                    background: #00b38a;
                }
                & > div:first-child{
                    display: flex;
                    align-items: center;
                    .iconfont{
                      margin-right: 8px;
                      font-size: 24px;
                     }
                }
               
            } 
          
        }
        .echarts_content_box{
          width: 100%;
          height: 320px;
          margin-top: 15px;
        }
        .top_content_box{
          width: 100%;
          height: 320px;
          margin-top: 15px;
          display: flex;   
          .pset_left_box{
              width: 400px;
              text-align: center;
              .mt-40{
                 margin-top: 40px;
              }
          } 
          .pset_right_box{
              flex: 1;
              margin-left: 20px;
          }

        }
        .date-picker{
            border-radius: 5px;
            margin-left: 10px;
        }
     }
 }
</style>